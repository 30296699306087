@import "./mixins";

*::before,
*::after {
  box-sizing: border-box;
}

.container {
  max-width: calc(950px + 40px);
  padding: 0 15px;
  margin: 0 auto;
  @include media("<=576px") {
    padding-left: 15px;
    padding-right: 15px;
  }
}

.default-layout {
  position: relative;
  flex: 1 1 100%;
  padding: 0 15px;

  @include media("<768px") {
    padding: 0;
  }
  &__container {
    position: relative;
    margin: 0 auto;
    padding: 0 0 20px 0;
    background: rgba(0, 0, 0, 0.23);
    color: #fff;
    z-index: 5;
    @include media("<768px") {
      margin: 10px auto;
    }

    &::before {
      content: "";
      display: block;
      width: calc(100% + 30px);
      height: 300px;
      background: rgba(255, 255, 255, 15%);
      position: absolute;
      bottom: -165px;
      left: 0;
      right: 0;
      width: 100%;
      z-index: -1;

      @media screen and (max-width: 991px) {
        bottom: -190px;
      }
      @media screen and (max-width: 900px) {
        bottom: -195px;
      }
      @media screen and (max-width: 870px) {
        bottom: -210px;
      }
      @media screen and (max-width: 768px) {
        display: none;
      }
    }
  }
  &__main-content {
  }
}

.footer {
  z-index: 10;
  &__container {
    position: relative;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    column-gap: 30px;
    padding-bottom: 20px;

    @include media("<=991px") {
      margin: 0 15px 15px 15px;
    }

    @include media("<=768px") {
      grid-template-columns: 1fr;
      row-gap: 30px;
    }
  }

  &__list {
    font-family: "GeorgiaDev";
  }

  &__list-title {
    color: #d8c394;
    margin-bottom: 10px;
  }

  &__list-item {
    display: block;
    color: #fff;
    text-decoration: none;
    transition: all 0.3s ease;
    &:not(:last-child) {
      margin-bottom: 5px;
    }
    span {
      color: #d4caa2;
    }
    &:hover {
      color: #d8c394;
    }
    &_text {
      &:hover {
        color: #fff;
      }
    }
  }
}

.middle-info {
  margin: 0 15px;
  &__container {
    display: flex;
    flex-direction: column;

    padding: 20px 0 0 0;

    background-image: url("../public/bg_header.webp");
    background-repeat: no-repeat;
    background-size: cover;
  }

  &__content {
    display: flex;
    align-items: center;

    margin-left: auto;
    text-align: left;
    color: #fff;
    padding: 0 15px;
    margin-bottom: 20px;

    min-height: 130px;
    @include media("<768px") {
      text-align: center;
      margin-right: auto;
    }
  }

  &__link {
    color: #d4caa2;
    text-decoration: underline;
    &:hover {
      text-decoration: none;
    }
    &_white {
      color: #fff;
      text-decoration: none;
      &:hover {
        text-decoration: underline;
      }
    }
  }

  &__description {
    padding: 0 15px;
    text-align: right;

    line-height: 33px;
    font-size: 18px;
    background: rgba(0, 0, 0, 0.5);
    @include media("<768px") {
      font-size: 16px;
      text-align: center;
    }
  }
}

.middle-nav {
  position: relative;
  &::before {
    content: "";
    position: absolute;
    top: 33px;
    right: 0;
    left: 0;
    height: 248px;
    background: rgba(255, 255, 255, 15%);
    z-index: -1;
    @include media("<=767px") {
      display: none;
    }
  }
  &__container {
    position: relative;
    height: 33px;
    padding: 0 15px;

    background-image: url("../public/bg_nav.webp");
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 7px 7px 0 0;
    overflow: hidden;
    box-shadow: 0px 2px 5px 0px rgb(0 0 0 / 93%);
    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border: 1px solid rgb(142, 133, 118);
      border-top-left-radius: 7px;
      border-top-right-radius: 7px;
      background-image: linear-gradient(
        360deg,
        rgba(0, 0, 0, 0.2) 0%,
        rgba(220, 213, 198, 0.2) 60%,
        rgba(220, 213, 198, 0.2) 77%,
        rgba(50, 50, 50, 0.1) 100%
      );
      @include media("<=991px") {
        display: none;
      }
    }

    @include media("<=991px") {
      overflow: visible;
    }
  }
  &__items-with-btn {
    position: relative;

    display: flex;
    height: 40px;
    margin: 0 15px;

    background-image: url("../public/bg_nav.webp");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    overflow: hidden;
    @include media("<=991px") {
      justify-content: flex-end;
      overflow: visible;
    }
  }
  &__items {
    position: relative;
    display: flex;
    height: 100%;

    z-index: 3;
    list-style-type: none;
    margin: 0;
    padding: 0;
    border-width: 1px;
    border-top-width: 1px;
    border-color: rgb(142, 133, 118);
    box-shadow: 0px 0px 5px 1px rgb(0 0 0 / 93%);
    border-style: solid;
    &::after {
      content: "";
      display: block;
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      right: 0;
      pointer-events: none;
      background-image: -moz-linear-gradient(
        90deg,
        rgba(0, 0, 0, 0) 0%,
        rgba(220, 213, 198, 0) 60%,
        rgba(220, 213, 198, 0) 77%,
        rgba(50, 50, 50, 0.6) 100%
      );
      background-image: -webkit-linear-gradient(
        90deg,
        rgba(0, 0, 0, 0) 0%,
        rgba(220, 213, 198, 0) 60%,
        rgba(220, 213, 198, 0) 77%,
        rgba(50, 50, 50, 0.6) 100%
      );
      background-image: -ms-linear-gradient(
        90deg,
        rgba(0, 0, 0, 0) 0%,
        rgba(220, 213, 198, 0) 60%,
        rgba(220, 213, 198, 0) 77%,
        rgba(50, 50, 50, 0.6) 100%
      );
      z-index: -1;
    }
    @include media("<=991px") {
      position: absolute;
      top: calc(100% + 10px);
      left: 0;
      right: 0;
      height: auto;
      flex-direction: column;
      background: rgb(231, 218, 168);
      border-color: rgb(142, 133, 118);
      box-shadow: 0px 20px 20px -3px rgba(0, 0, 0, 0.3);

      transition: all 0.3s ease;
      z-index: -1;
      opacity: 0;
      visibility: hidden;

      &::after {
        content: "";
        display: block;
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        right: 0;
        pointer-events: none;
        background-image: -webkit-linear-gradient(
          90deg,
          rgba(0, 0, 0, 0) 0%,
          rgba(220, 213, 198, 0) 60%,
          rgba(220, 213, 198, 0) 77%,
          rgba(50, 50, 50, 0.6) 100%
        );
        z-index: -1;
      }

      &.active {
        z-index: 200;
        opacity: 1;
        visibility: visible;
        top: 100%;
      }
    }
  }
  &__item {
    position: relative;

    display: inline-flex;
    justify-content: center;
    align-items: center;

    color: #fff;
    padding: 0 10px;
    height: 100%;
    flex: 1 1 auto;
    text-decoration: none;
    text-align: center;

    font-family: "GeorgiaDev";
    color: rgb(0, 0, 0);
    font-weight: 600;
    text-align: center;
    font-size: 14px;
    text-shadow: 1px 1px 0px rgb(255 255 255 / 65%);
    transition: all 0.3s ease;

    &::before {
      content: "";
      display: block;
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      right: 0;
      pointer-events: none;
      background-image: linear-gradient(
        360deg,
        rgba(0, 0, 0, 0) 0%,
        rgba(220, 213, 198, 0) 60%,
        rgba(220, 213, 198, 0) 77%,
        rgba(50, 50, 50, 0.6) 100%
      );
      @include media("<=991px") {
        display: none;
      }
    }

    &:hover {
      background-color: #e4d7bc;
      background-image: linear-gradient(
        to top,
        rgba(0, 0, 0, 0.4) 0%,
        rgba(255, 255, 255, 0) 100%
      );
      border-image-slice: 1;
      text-decoration: none;
      box-sizing: border-box;
      @include media("<=991px") {
        background-color: #e4d7bc;
        background-image: -webkit-linear-gradient(
          90deg,
          rgb(224 211 184) 0%,
          rgb(141 133 116) 100%
        );
        border-image-slice: 1;
        box-sizing: border-box;
        text-decoration: none;
      }
    }

    @include media("<=991px") {
      width: 100%;
      padding: 0 15px;
      font-size: 14px;

      color: rgb(0, 0, 0);
      font-weight: bold;
      text-align: center;
      text-shadow: 1px 1px 0px rgb(255 255 255 / 65%);
      padding: 0 20px;
      line-height: 31px;
      text-decoration: none;
      white-space: nowrap;
      border: 1px solid transparent;
    }

    &.active {
      background-color: rgba(212, 189, 142, 0.6);
      @include media("<=991px") {
        background-color: transparent;
        font-weight: 600;
      }
    }

    img {
      @include media("<=991px") {
        display: none;
      }
    }

    &_first {
      span {
        display: none;
        @include media("<=991px") {
          display: inline-block;
        }
      }
    }
  }
  &__menu-btn {
    display: none;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 60px;
    transition: all 0.3s ease;
    &:hover,
    &:focus {
      background-color: rgba(212, 189, 142, 0.6);
    }
    @include media("<=991px") {
      display: flex;
      margin-left: auto;
    }
    svg {
      font-size: 30px;
      color: #000;
      fill: #000;
    }
  }
}

.welcome-slider {
  &__container {
    // aspect-ratio: 1030 / 400;
    height: 340px;
    background-color: #000;
  }
}

.welcome-info {
  &__container {
  }

  &__main-content {
    background: #c8c4b9;
    display: flex;
    color: #000;
    font-family: GeorgiaDev;
    gap: 30px;
    margin: 15px 15px 30px 15px;
    padding: 30px 40px;
    min-height: 65px;
    @include media("<=768px") {
      flex-direction: column;
      gap: 25px;
      padding: 20px 30px;
    }
  }

  &__item {
  }

  &__title {
    display: inline-block;
    text-decoration: none;
    font-weight: 700;
    color: rgb(99, 85, 45);
    margin-bottom: 10px;
    font-size: 20px;
    @include media("<=768px") {
      font-size: 18px;
    }
  }
  &__description {
    font-size: 14px;
    font-weight: 600;
    font-family: "VerdanaDev";
    a {
      font-weight: 700;
      color: rgb(99, 85, 45);
      &:hover {
        text-decoration: none;
      }
    }
  }
}

.welcome-info-alt {
  &__container {
  }

  &__main-content {
    background: rgba(255, 255, 255, 0.2);
    display: flex;
    color: #000;
    font-family: GeorgiaDev;
    gap: 10px;
    margin: 20px 15px 0 15px;
    padding: 30px 40px;
    min-height: 65px;
    text-align: center;
    background-image: url("../public/features-bg.png");
    background-repeat: no-repeat;
    background-size: cover;
    @include media("<=768px") {
      flex-direction: column;
      gap: 25px;
      padding: 20px 30px;
    }
  }

  &__item {
    flex-basis: 33.33%;
  }

  &__title {
    display: inline-block;
    text-decoration: none;
    font-weight: 700;
    margin-bottom: 10px;
    font-size: 14px;
    @include media("<=768px") {
      margin-bottom: 5px;
      font-size: 16px;
    }
  }
  &__description {
    font-size: 15px;
    a {
      font-weight: 700;
      color: rgb(99, 85, 45);
    }
  }
}

.swiper-pagination {
  bottom: 30px !important;
  right: 40px !important;
  left: unset !important;
  display: flex;
  justify-content: flex-end;
  .swiper-pagination-bullet {
    width: 10px;
    height: 10px;
    flex: 0 0 10px;

    opacity: 1;
    border-color: rgb(0, 0, 0);
    background-color: rgb(39, 39, 39);
    box-shadow: 0.707px 0.707px 4px 0px rgb(0 0 0);
    margin-left: 10px;
  }
  .swiper-pagination-bullet-active {
    background-color: rgb(249, 238, 214);
  }
}

.custom-welcome-slider {
  padding: 0 15px;
  @include media("<=768px") {
    padding: 0;
  }
}

.billet {
  background-image: url("../public/tab-bg.png");
  background-color: rgb(229, 231, 235);
  background-repeat: no-repeat;
  background-size: cover;
  border-style: solid;
  z-index: 2;
  position: relative;
  box-shadow: 0px 3px 7px 1px black;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  border-radius: 8px;
  border: 1px solid #d2c8a0;
  border-left-width: 2px;
  border-right-width: 2px;
  border-left-color: #f6ebd5;
  border-right-color: #f6ebd5;
  &_2 {
    background: #c8c4b9;
    border-style: solid;
    border-width: 1px;
    border-color: rgb(231, 218, 168);
    border-radius: 8px;
    box-shadow: 1.089px 1.677px 10px 0px rgb(0 0 0),
      inset 0px 0px 10px 0px rgb(0 0 0);
  }
  &_3 {
    background-image: url("../public/features-bg.png");
    background-size: cover;
    border-style: solid;
    border-width: 1px;
    border-color: rgb(128, 115, 64);
    border-radius: 8px;
    background-color: rgb(187, 183, 173);
    box-shadow: 0px 0px 3px 0px rgb(0 0 0), inset 0px 0px 10px 1px rgb(0 0 0);
    text-align: center;
  }
  &_4 {
    margin-top: 15px;
    height: 65px;
    border-style: solid;
    border-width: 1px;
    border-color: rgb(128, 115, 64);
    border-radius: 8px;
    background: #d1c9b5;
    background: radial-gradient(
      ellipse at center,
      #d1c9b5 0%,
      #c7bda6 65%,
      #e6ddc8 87%,
      #979080 100%
    );
    box-shadow: 0px 0px 3px 0px rgb(0 0 0), inset 0px 0px 10px 1px rgb(0 0 0);
    margin-bottom: 8px;
    border-radius: 8px;
  }
  &_5 {
    background-image: url("../public/features-bg.png");
    background-size: cover;
    border-style: solid;
    border-width: 1px;
    border-color: rgb(128, 115, 64);
    border-radius: 8px;
    background-color: rgb(187, 183, 173);
    box-shadow: 0px 0px 3px 0px rgb(0 0 0), inset 0px 0px 10px 1px rgb(0 0 0);
    padding: 15px 35px 15px;
    text-align: center;
    margin-top: 15px;
    margin-bottom: 10px;
  }
}

.error-page {
  &__container {
    height: 500px;
  }
  &__title {
    margin: 20px auto;
    text-align: center;
    font-size: 20px;
  }
}

.primer-dogovora {
  &__container {
    display: flex;
    justify-content: space-between;
    @include media("<=768px") {
      display: block;
    }
  }

  &__content {
    width: 320px;
    flex: 0 0 320px;
    margin-right: 20px;
    @include media("<=768px") {
      width: auto;
      margin-bottom: 20px;
    }
  }

  &__images {
    width: 200px;
    flex: 0 0 200px;
    height: auto;
    aspect-ratio: 16 / 2;
    @include media("<=768px") {
      width: auto;
      max-width: 300px;
    }
    img {
    }
  }
}
